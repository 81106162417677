.Home {
  height: 100%;
  padding: 50px;
}
.Home h1 {
  margin-bottom: 30px;
}
.Home h4 {
  margin: 30px 0;
}
.Home--container {
  padding: 0 12px;
}
.Home--container.h-p-10 {
  padding: 0 10px;
}
.Home--container.small-res {
  display: none;
}
.Home--container.small-res .contact-btn {
  padding: 6px 15px;
  margin: 20px;
  border: 1px solid #1976d2;
}
.Home--container .avatar {
  margin: 0 auto;
  width: 256px;
  height: 256px;
}
.Home--container:last-child {
  padding-bottom: 100px;
}
.Home .divider {
  margin: 50px 0;
}
.Home .divider.small-res {
  display: none;
}

@media only screen and (max-width: 960px) {
  .Home--container .full-name,
  .Home--container .role-description {
    margin: 10px 0;
  }
  .Home--container.small-res {
    display: block;
  }
  .Home .divider.small-res {
    display: block;
  }
}
@media only screen and (max-width: 500px) {
  .Home {
    padding: 20px 0;
  }
}
@media only screen and (max-width: 450px) {
  .Home--container .avatar {
    width: 90px;
    height: 90px;
  }
}

