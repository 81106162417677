.ContactMe {
  padding-bottom: 10px;
}
.ContactMe .btn {
  border: 1px solid #1976d2;
}
.ContactMe .btn.back-btn {
  padding: 6px 15px;
  margin: 20px;
}
.ContactMe .btn.Mui-disabled {
  border-color: rgba(0, 0, 0, 0.26) !important;
}
.ContactMe--form-container {
  display: flex;
  justify-content: center;
}
.ContactMe--form-container--form-group {
  padding: 20px;
  width: 600px;
}
.ContactMe--form-container--form-control {
  margin: 10px 0 !important;
}
.ContactMe--dialog-section {
  display: none;
}
.ContactMe--panel {
  margin: 30px !important;
  border-radius: 8px !important;
  box-shadow: 2px 2px 17px 6px rgba(0, 0, 0, 0.22) !important;
}
.ContactMe--panel.Mui-expanded .MuiAccordionSummary-root {
  border-bottom: 1px solid rgba(0, 0, 0, 0.26);
}
.ContactMe--panel .MuiAccordionDetails-root {
  padding: 16px;
}
.ContactMe--panel::before {
  display: none;
}
.ContactMe--panel .MuiAccordionSummary-content .MuiTypography-root {
  font-size: 22px;
}
.ContactMe--panel .MuiCollapse-root .MuiTypography-root {
  display: flex;
  flex-direction: column;
}
.ContactMe--panel .MuiCollapse-root .MuiTypography-root .list-item {
  display: flex;
  flex-direction: column;
}
.ContactMe--panel .MuiCollapse-root .MuiTypography-root .list-item--title, .ContactMe--panel .MuiCollapse-root .MuiTypography-root .list-item--text {
  margin: 10px 20px;
}
.ContactMe--panel .MuiCollapse-root .MuiTypography-root .list-item--title {
  font-weight: 700;
}

@media screen and (min-width: 471px) and (max-width: 960px) {
  .ContactMe--form-container--form-group {
    width: 400px;
  }
  .ContactMe--dialog-section {
    display: block;
  }
  .ContactMe--panel {
    display: none;
  }
}
@media screen and (max-width: 470px) {
  .ContactMe--form-container--form-group {
    width: 270px;
  }
  .ContactMe--dialog-section {
    display: block;
  }
  .ContactMe--panel {
    display: none;
  }
}


