@import "../../../src/assets/styles/vars";

.EducationStepper {
  .MuiBox-root {
    max-width: 90%;

    &.block {
      max-width: 100%;
    }

    ul {
      list-style: none;

      .list-item {
        margin: 20px 0;
        display: flex;
        align-items: center;

        svg path {
          fill: green;
        }

        li {
          margin-left: 10px;
        }
      }
    }
  }
}