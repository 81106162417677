@import "../../../src/assets/styles/vars";
@import "../../../src/assets/styles/mixins/contacts";

.GraphicContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../../../public/images/bg-img.jpg');

  flex-direction: column;

  .avatar {
    width: 320px;
    height: 320px;
  }

  @include contact-btn;
}