.main-btn {
  border: 1px solid #1976d2 !important;
  width: 100%;
}
.main-btn.Mui-disabled {
  border-color: rgba(0, 0, 0, 0.26) !important;
}

.popup-container {
  position: absolute;
  left: -55px;
  top: -185px;
  padding: 20px;
  background: #fff;
  width: 450px;
  height: 170px;
  z-index: 10;
  border-radius: 8px;
  -webkit-box-shadow: 2px 4px 23px 7px rgba(0, 0, 0, 0.53);
  -moz-box-shadow: 2px 4px 23px 7px rgba(0, 0, 0, 0.53);
  box-shadow: 2px 4px 23px 7px rgba(0, 0, 0, 0.53);
}
.popup-container::after {
  content: "";
  position: absolute;
  top: 170px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #fff;
}
.popup-container--body {
  margin-bottom: 20px;
}
.popup-container--footer {
  display: flex;
  flex-direction: row-reverse;
}
.popup-container--footer .btn {
  padding: 6px 12px;
}
.popup-container--footer .btn-cancel {
  margin-left: 10px;
}

@media screen and (max-width: 510px) and (orientation: portrait) {
  .popup-container {
    width: 300px;
    height: 210px;
    top: -225px;
    left: -115px;
  }
  .popup-container::after {
    top: 210px;
  }
}


