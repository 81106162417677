// resolution
$tablet-view: 960px;

// border
$m-border-radius: 15px;

// colors
$blue: #1976d2;
$hover-color: #8EA7E9;
$active-color: #7286D3;
$global-bg-color: #E5E0FF;
$divider-color: rgba(0, 0, 0, 0.12);
$pink: #E3ACF9;
$grey: #ECE8DD;
$green: #007C77;
$raspberry: #E69E94;
$bg-red-gradient: linear-gradient(136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%);
$box-shadow: 0 4px 10px 0 rgba(0,0,0,.25);
