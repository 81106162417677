@import "../src/assets/styles/vars";

.App {
  height: 100vh;
  display: flex;
  text-align: center;
  overflow-x: hidden;
  padding: 50px 100px;
  background: $global-bg-color;

  &--container {
    &:not(.navbar) {
      width: calc(100vw / 1.3);
    }

    &.context {
      background: #fff;
      overflow-y: auto;
      overflow-x: hidden;
      border-radius: 0 $m-border-radius $m-border-radius 0;
    }
  }
}

@media only screen and (max-width: $tablet-view) {
  .App {
    flex-direction: column;
    padding: 0;

    &--container {
      &.graphic {
        display: none;
      }

      &.context {
        width: 100vw;
        border-radius: 0;
      }
    }
  }
}

// general styles

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Consolas', sans-serif;
}

ul {
  text-align: left;
}

.uppercase {
  text-transform: uppercase;
}

button {
  cursor: pointer;
}

a {
  outline: none;
}

u {
  color: blue;
  text-decoration-color: blue;
}

.btn-icon {
  margin-right: 8px;
}
