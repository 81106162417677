@mixin list-items {
  .list-item {
    display: flex;
    flex-direction: column;

    &--title,
    &--text {
      margin: 10px 20px;
    }

    &--title {
      font-weight: 700;
    }
  }
}