.dialog--title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, 0.26);
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
}
.dialog--title .close-icon {
  cursor: pointer;
}
.dialog .MuiDialog-container {
  padding: 0;
}
.dialog .MuiDialog-container .MuiPaper-root {
  margin: 0;
  width: 90%;
}
.dialog .MuiDialog-container .MuiPaper-root .list-item {
  display: flex;
  flex-direction: column;
}
.dialog .MuiDialog-container .MuiPaper-root .list-item--title, .dialog .MuiDialog-container .MuiPaper-root .list-item--text {
  margin: 10px 20px;
}
.dialog .MuiDialog-container .MuiPaper-root .list-item--title {
  font-weight: 700;
}


