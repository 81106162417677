@import "../../../src/assets/styles/vars";
@import "../../../src/assets/styles/mixins/privacy-policy";

.ContactMe {
  padding-bottom: 10px;

  .btn {
    border: 1px solid $blue;

    &.back-btn {
      padding: 6px 15px;
      margin: 20px;
    }

    &.Mui-disabled {
      border-color: rgba(0, 0, 0, 0.26) !important;
    }
  }

  &--form-container {
    display: flex;
    justify-content: center;

    &--form {
      &-group {
        padding: 20px;
        width: 600px;
      }

      &-control {
        margin: 10px 0 !important;
      }
    }
  }

  &--dialog-section {
    display: none;
  }

  &--panel {
    margin: 30px !important;
    border-radius: 8px !important;
    box-shadow: 2px 2px 17px 6px rgba(0, 0, 0, 0.22) !important;

    &.Mui-expanded {
      .MuiAccordionSummary-root {
        border-bottom: 1px solid rgba(0, 0, 0, 0.26);
      }
    }

    .MuiAccordionDetails-root {
      padding: 16px;
    }

    &::before {
      display: none;
    }

    .MuiAccordionSummary {
      &-content {
        .MuiTypography-root {
          font-size: 22px;
        }
      }
    }

    .MuiCollapse-root {
      .MuiTypography-root {
        display: flex;
        flex-direction: column;

        @include list-items;
      }
    }
  }
}

@media screen and (min-width: 471px) and (max-width: $tablet-view) {
  .ContactMe {
    &--form-container {
      &--form {
        &-group {
          width: 400px;
        }
      }
    }

    &--dialog-section {
      display: block;
    }

    &--panel {
      display: none;
    }
  }
}

@media screen and (max-width: 470px) {
  .ContactMe {
    &--form-container {
      &--form {
        &-group {
          width: 270px;
        }
      }
    }

    &--dialog-section {
      display: block;
    }

    &--panel {
      display: none;
    }
  }
}