.App {
  height: 100vh;
  display: flex;
  text-align: center;
  overflow-x: hidden;
  padding: 50px 100px;
  background: #E5E0FF;
}
.App--container:not(.navbar) {
  width: 76.9230769231vw;
}
.App--container.context {
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0 15px 15px 0;
}

@media only screen and (max-width: 960px) {
  .App {
    flex-direction: column;
    padding: 0;
  }
  .App--container.graphic {
    display: none;
  }
  .App--container.context {
    width: 100vw;
    border-radius: 0;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Consolas", sans-serif;
}

ul {
  text-align: left;
}

.uppercase {
  text-transform: uppercase;
}

button {
  cursor: pointer;
}

a {
  outline: none;
}

u {
  color: blue;
  text-decoration-color: blue;
}

.btn-icon {
  margin-right: 8px;
}

