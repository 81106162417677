@import "../../../src/assets/styles/vars";
@import "../../../src/assets/styles/mixins/privacy-policy";

.dialog {
  &--title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //text-align: center;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(0, 0, 0, 0.26);
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;

    .close-icon {
      cursor: pointer;
    }
  }

  .MuiDialog-container {
    padding: 0;

    .MuiPaper-root {
      margin: 0;
      width: 90%;

      @include list-items;
    }
  }
}