@import "../../../src/assets/styles/vars";
@import "../../../src/assets/styles/mixins/contacts";

.Home {
  height: 100%;
  padding: 50px;

  h1 {
    margin-bottom: 30px;
  }

  h4 {
    margin: 30px 0;
  }

  &--container {
    padding: 0 12px;

    &.h-p-10 {
      padding: 0 10px;
    }

    &.small-res {
      display: none;

      @include contact-btn;
    }

    .avatar {
      margin: 0 auto;
      width: 256px;
      height: 256px;
    }

    &:last-child {
      padding-bottom: 100px;
    }
  }

  .divider {
    margin: 50px 0;

    &.small-res {
      display: none;
    }
  }
}

@media only screen and (max-width: $tablet-view) {
  .Home {
    &--container {
      .full-name,
      .role-description {
        margin: 10px 0;
      }

      &.small-res {
        display: block;
      }
    }

    .divider {
      &.small-res {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .Home {
    padding: 20px 0;
  }
}

@media only screen and (max-width: 450px) {
  .Home {
    &--container {
      .avatar {
        width: 90px;
        height: 90px;
      }
    }
  }
}