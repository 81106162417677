.AboutMe ul {
  list-style: none;
  margin: 5px 0;
}
.AboutMe ul .list-item {
  margin: 20px 0;
  display: flex;
  align-items: center;
}
.AboutMe ul .list-item .icon-container {
  display: flex;
  padding: 3px;
  border-radius: 50%;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
}
.AboutMe ul .list-item .icon-container svg path {
  fill: #fff;
}
.AboutMe ul .list-item:nth-child(2n+1) .icon-container {
  background: #E69E94;
}
.AboutMe ul .list-item:nth-child(2n+2) .icon-container {
  background: #8EA7E9;
}
.AboutMe ul .list-item li {
  margin-left: 10px;
}

