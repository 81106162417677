.EducationStepper .MuiBox-root {
  max-width: 90%;
}
.EducationStepper .MuiBox-root.block {
  max-width: 100%;
}
.EducationStepper .MuiBox-root ul {
  list-style: none;
}
.EducationStepper .MuiBox-root ul .list-item {
  margin: 20px 0;
  display: flex;
  align-items: center;
}
.EducationStepper .MuiBox-root ul .list-item svg path {
  fill: green;
}
.EducationStepper .MuiBox-root ul .list-item li {
  margin-left: 10px;
}

