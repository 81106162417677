.Navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 20px;
  height: calc(100vh - 100px);
  background: #fff;
  position: relative;
  border-radius: 15px 0 0 15px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.Navbar .localization,
.Navbar .social-media {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}
.Navbar .social-media a .icon, .Navbar .social-media:visited .icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: #E3ACF9;
  transition: color 0.4s ease-in;
}
.Navbar .social-media a .icon:hover, .Navbar .social-media:visited .icon:hover {
  color: #8EA7E9;
}
.Navbar .copyright {
  position: absolute;
  bottom: 20px;
}
.Navbar-burger-wrapper {
  display: none;
}
.Navbar-burger-btn {
  margin: 0 !important;
}

.localization .btn {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid transparent;
  border-radius: 8px;
  transition: background 0.3s ease-in, color 0.3s ease-in;
}
.localization .btn:hover {
  background: #8EA7E9;
  color: #fff;
}
.localization .btn.active {
  background: #7286D3;
  color: #fff;
}

@media only screen and (max-width: 960px) {
  .Navbar {
    height: 50px;
    border-radius: 0;
    padding: 8px 20px;
    box-shadow: 0 5px 5px -1px rgba(0, 0, 0, 0.27);
  }
  .Navbar-regular {
    display: none;
  }
  .Navbar-burger-wrapper {
    display: block;
  }
  #menu .MuiPaper-elevation {
    display: flex;
    justify-content: center;
    padding: 8px 10px;
    min-width: 80px;
  }
  #menu .MuiPaper-elevation .MuiList-root .localization .btn {
    width: 30px;
    height: 30px;
    padding: 0;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }
  #menu .MuiPaper-elevation .MuiList-root .social-media a svg {
    width: 30px;
    height: 30px;
  }
}

