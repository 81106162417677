.GraphicContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../../public/images/bg-img.jpg");
  flex-direction: column;
}
.GraphicContainer .avatar {
  width: 320px;
  height: 320px;
}
.GraphicContainer .contact-btn {
  padding: 6px 15px;
  margin: 20px;
  border: 1px solid #1976d2;
}


