@import "../../../src/assets/styles/vars";

.Navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 20px;
  height: calc(100vh - 100px);
  background: #fff;
  position: relative;
  border-radius: $m-border-radius 0 0 $m-border-radius;
  border-right: 1px solid $divider-color;

  .localization,
  .social-media {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
  }

  .social-media {
    a,
    &:visited {
      .icon {
        cursor: pointer;
        width: 30px;
        height: 30px;
        color: $pink;
        transition: color .4s ease-in;

        &:hover {
          color: $hover-color;
        }
      }
    }
  }

  .copyright {
    position: absolute;
    bottom: 20px;
  }

  &-burger {
    &-wrapper {
      display: none;
    }

    &-btn {
      margin: 0 !important;
    }
  }
}

.localization {
  .btn {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid transparent;
    border-radius: 8px;
    transition: background .3s ease-in, color .3s ease-in;

    &:hover {
      background: $hover-color;
      color: #fff;
    }

    &.active {
      background: $active-color;
      color: #fff;
    }
  }
}

@media only screen and (max-width: $tablet-view) {
  .Navbar {
    height: 50px;
    border-radius: 0;
    padding: 8px 20px;

    box-shadow: 0 5px 5px -1px rgba(0, 0, 0, 0.27);

    &-regular {
      display: none;
    }

    &-burger {
      &-wrapper {
        display: block;
      }
    }
  }

  // burger menu items
  #menu {
    .MuiPaper-elevation {
      display: flex;
      justify-content: center;
      padding: 8px 10px;
      min-width: 80px;

      .MuiList-root {
        .localization {
          .btn {
            width: 30px;
            height: 30px;
            padding: 0;
            font-size: 16px;
            margin-top: 0;
            margin-bottom: 0;
          }
        }

        .social-media {
          a svg {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
}
