@import "../../../src/assets/styles/vars";

.AboutMe {
  ul {
    list-style: none;
    margin: 5px 0;

    .list-item {
      margin: 20px 0;
      display: flex;
      align-items: center;

      .icon-container {
        display: flex;
        padding: 3px;
        border-radius: 50%;
        box-shadow: $box-shadow;

        svg path {
          fill: #fff;
        }
      }

      &:nth-child(2n + 1) {
        .icon-container {
          background: $raspberry;
        }
      }

      &:nth-child(2n + 2) {
        .icon-container {
          background: $hover-color;
        }
      }

      li {
        margin-left: 10px;
      }
    }
  }
}